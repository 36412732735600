// Code
//––––––––––––––––––––––––––––––––––––––––––––––––––

code {
    padding: .2rem .5rem;
    margin: 0 .2rem;
    font-size: 90%;
    white-space: nowrap;
    background: lighten($light-grey, 6.4%);
    border: 1px solid $light-grey;
    border-radius: $global-radius;
}

pre > code {
    display: block;
    padding: 1rem 1.5rem;
    white-space: pre;
}
