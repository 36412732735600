// Buttons
//––––––––––––––––––––––––––––––––––––––––––––––––––

.button,
button {
    display: inline-block;
    // height: 38px;
    padding: 10px;
    color: $primary-color;
    text-align: center;
    font-size: 11px;
    font-weight: 600;
    // line-height: 38px;
    letter-spacing: .1rem;
    text-transform: uppercase;
    text-decoration: none;
    // white-space: nowrap;
    background-color: lighten($light-grey, 20%);
    border-radius: $global-radius;
    cursor: pointer;
    box-sizing: border-box;
    -webkit-transition: all 0.2s; /* Safari */
    transition: all 0.2s ease-in-out;
}

input {
    &[type="submit"],
    &[type="reset"],
    &[type="button"] {
        display: inline-block;
        // height: 38px;
        padding: 10px;
        color: $secondary-color;
        text-align: center;
        font-size: 11px;
        font-weight: 600;
        // line-height: 38px;
        letter-spacing: .1rem;
        text-transform: uppercase;
        text-decoration: none;
        // white-space: nowrap;
        background-color: transparent;
        border-radius: $global-radius;
        cursor: pointer;
        box-sizing: border-box;
    }
}

.button:hover,
button:hover {
    color: darken($primary-color, 5%);
    background-color: darken($light-grey, 10%);
    border-color: lighten($dark-grey, 33.3%);
    outline: 0;
}

input {
    &[type="submit"]:hover,
    &[type="reset"]:hover,
    &[type="button"]:hover {
        color: $dark-grey;
        border: none;
        outline: 0;
    }
}

.button:focus,
button:focus {
    color: $dark-grey;
    border-color: $primary-color;
    outline: 0;
}

input {
    &[type="submit"]:focus,
    &[type="reset"]:focus,
    &[type="button"]:focus {
        color: $dark-grey;
        border: none;
        outline: 0;
    }
}

.button.button-primary,
button.button-primary {
    color: #fff;
    background-color: $primary-color;
    border: none;
}

input {
    &[type="submit"].button-primary,
    &[type="reset"].button-primary,
    &[type="button"].button-primary {
        color: #fff;
        background-color: $primary-color;
        border: none;
    }
}

.button.button-primary:hover,
button.button-primary:hover {
    color: #fff;
    background-color: darken($primary-color, 10%);;
    border-color: $link-color;
}

input {
    &[type="submit"].button-primary:hover,
    &[type="reset"].button-primary:hover,
    &[type="button"].button-primary:hover {
        color: #fff;
        background-color: $link-color;
        border-color: $link-color;
    }
}

.button.button-primary:focus,
button.button-primary:focus {
    color: #fff;
    background-color: darken($light-grey, 30%);
    border-color: $dark-grey;
}

input {
    &[type="submit"].button-primary:focus,
    &[type="reset"].button-primary:focus,
    &[type="button"].button-primary:focus {
        color: #fff;
        background-color: $primary-color;
        border-color: $link-color;
    }
    &[type="email"],
    &[type="number"],
    &[type="search"],
    &[type="text"],
    &[type="tel"],
    &[type="url"],
    &[type="password"] {
        display: inline-block;
        padding: 10px; // The 6px vertically centers text on FF, ignored by Webkit
        background-color: #fff;
        border: 1px solid lighten($border-color, 8.8%);
        border-radius: $global-radius;
        box-shadow: none;
        box-sizing: border-box;
    }
}
