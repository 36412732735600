@import "skeleton";

// Custom variables
.content-wrapper {
    width: 100%;
    background-color: #fff;
}
.content {
    color: #58595c;
    h1, h2, h3, h4, h5, h6, .separation, .titled {
        color: $primary-color;
    }
    .subtitled {
        color: $secondary-color;
    }
    span.copy {
        font-size: 1rem;
        line-height: 1rem;
        display: block;
        text-align: right;
    }
}
.content ul {
    list-style-type: none;
}
.content ul li:before {
    content: '• ';
    color: $primary-color;
}

// Custom Css

header {
    position: fixed;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    z-index: 500;
    text-align: center;
    -webkit-transition: all 0.6s; /* Safari */
    transition: all 0.6s ease-in-out;
    // background-color: rgba(0,0,0,0.95);
    @media (#{$bp-larger-than-tablet}) {
        // background-color: transparent;
    }
    &.scrolled {
        background-color: darken(rgba(0,0,0,.95), 10%);
    }
}
.header-wrapper {
    width: 100%;
}
.header-wrapper > .logo {
    text-align: center;
    padding-left: 2rem;
    z-index: 200;
    float: left;
    box-sizing: border-box;
    a {
        text-transform: uppercase;
        text-decoration: none;
        img {
            width: 45px;
            height: 45px;
            vertical-align: middle;
            padding: 0.8rem 0;
            opacity: 0.75;
        }
    }
}
.header-wrapper > .menu-mobile-icon {
    display: inline-block;
    font-size: 3rem;
    float: right;
    padding-right: 2rem;
    padding-top: 1rem;
    cursor: pointer;
    @media (#{$bp-larger-than-tablet}) {
        display: none;
    }
}
.header-wrapper > nav.menu {
    display: none;
    height: 100vh;
    @media (#{$bp-larger-than-tablet}) {
        display: inline-block;
        height: 100%;
    }
}
nav.menu ul {
    display: block;
    list-style: none;
    margin-bottom: 0;
    padding-top: 65px;
    -webkit-transition: all 0.6s; /* Safari */
    transition: all 0.6s ease-in-out;
    li {
        display: block;
        margin-bottom: 1px;
        a {
            display: block;
            cursor: pointer;
            box-sizing: border-box;
            padding: 2.5rem 2rem;
            color: #FFFFFF;
            font-weight: 300;
            text-transform: uppercase;
            text-decoration: none;
            text-align: center;
            letter-spacing: 0.2rem;
            font-size: 1.2rem;
            line-height: 1rem;
            -webkit-transition: all 0.2s; /* Safari */
            transition: all 0.2s ease-in-out;
            &:hover {
                background-color: rgba(255,255,255,0.25);
            }
            &.active {
                background-color: rgba(255,255,255,0.25);
            }
            @media (#{$bp-larger-than-tablet}) {
                text-align: left;
            }
            &:before {
                content: "⎕ "
            }
        }
    }
    @media (#{$bp-larger-than-tablet}) {
        padding-top: 0;
        li {
            display: inline-block;
            a {
                display: inline-block;
            }
        }
    }
}
footer {
    width: 100%;
    margin-top: 1rem;
    padding: 5rem 0rem;
    color: darken($font-color, 35%);
    background-color: $dark-grey;
    text-align: center;
    @media (#{$bp-larger-than-tablet}) {
        margin-top: 10rem;
    }
    .footer-col-wrapper {
        padding: 0 1rem;
    }
    h5 {
        font-size: 1.5rem;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        margin-bottom: 0;
    }
    span {
        color: lighten($secondary-color, 15%);
    }
    p:first-of-type {
        margin-bottom: 0px;
    }
    p {
        font-size: 1.25rem;
        letter-spacing: .25rem;
        text-align: left;
        @media (#{$bp-larger-than-tablet}) {
            text-align: center;
        }
    }
}
.particles-js-canvas-el {
    position: absolute;
    top: 0;
    left: 0;
    // height: 100%;
    // background-color: #4C4C4C;
}
.gradient {
    // padding-top: 65px;
    // height: 100vh;
    background: linear-gradient(359deg, #134b3d, #0d203a);
    background-size: 400% 400%;
    margin-bottom: 7rem;
    -webkit-animation: softGradient 40s ease infinite;
    -moz-animation: softGradient 40s ease infinite;
    animation: softGradient 40s ease infinite;

    @-webkit-keyframes softGradient {
        0%{background-position:50% 0%}
        50%{background-position:51% 100%}
        100%{background-position:50% 0%}
    }
    @-moz-keyframes softGradient {
        0%{background-position:50% 0%}
        50%{background-position:51% 100%}
        100%{background-position:50% 0%}
    }
    @keyframes softGradient {
        0%{background-position:50% 0%}
        50%{background-position:51% 100%}
        100%{background-position:50% 0%}
    }
}
.square-elmt {
    position: absolute;
    box-sizing: border-box;
    top: 50%;
    left: 50%;
    margin-top: -65px;
    margin-left: -65px;
    width: 130px;
    height: 130px;
    border: 1px solid $font-color;
    color: $font-color;
    background-color: rgba(255,255,255,0.15);
    padding: 2rem;
    text-align: center;
    letter-spacing:normal;
    line-height: normal;
    .square-letters {
        // position: relative;
        font-size: 7rem;
        font-weight:300;
    }
    .square-number {
        position: absolute;
        font-size: 1.5rem;
        font-weight: 400;
        top: 0;
        left: 3px;

    }
}
p.square {
    position: relative;
    height: 100%;
}
.intro {
    position: relative;
    z-index: 100;
    box-sizing: border-box;
    width: 100%;
    margin: 8rem 0rem;
    padding: 0rem 1rem;
    letter-spacing: 2px;
    font-weight: 300;
    color: $font-color;
    a {
        text-decoration: none;
    }
    img {
        opacity: 0.75;
    }
    p.square {
        height: 130px;
    }
    h1 {
        text-transform: uppercase;
        letter-spacing: 1rem;
        font-size: 2.5rem;
        color: #FFFFFF;
    }
    h6 {
        font-size: 1.2rem;
        letter-spacing: 1rem;
        text-transform: uppercase;
        color: $font-color;
    }
    .arrow-down a {
        font-size: 3rem;
        color: #FFFFFF;
    }
    @media (#{$bp-larger-than-tablet}) {
        margin: 15rem 0rem;
    }
}
.separation {
    margin-top: 3rem;
    margin-bottom: 5rem;
    letter-spacing: 0.5rem;
    &:before {
        content: "--- • ---";
    }
}
.index {
    h2 {
        text-align: center;
    }
    p {
        text-align: justify;
    }
    .project-details {
        text-align: center;
    }
}

// Project Page

.project-page {}
.project-page .gradient {
    height: inherit;
    min-height: 300px;
}
.project-page .project-thumb {
    margin-bottom: 5rem;
}
.project-intro {
    position: relative;
    height: inherit;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 3rem;
}
.project-intro .project-thumb {
    padding: 8rem 1rem;
    p.square {
        min-height: 130px;
        margin-bottom: 2.5rem;
    }
    @media (#{$bp-larger-than-tablet}) {
        padding: 15rem 1rem;
    }
}
.project-thumb {
    position: relative;
    height: inherit;
    margin-bottom: 1rem;
    padding: 3rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 3rem;;
    p.square {
        min-height: 200px;
        margin-bottom: 0;
    }
}
.project-thumb::before {
    content: "";
    background-color: rgba(29, 32, 33, 0.7);
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.project-thumb .project-title {
    position: relative;
    text-align: center;
    a {
        text-decoration: none;
    }
    h2 {
        display: inline-block;;
        text-transform: uppercase;
        font-size: 2rem;
        letter-spacing: 0.5rem;
        border: 1px solid $border-color;
        color: #FFFFFF;
        background-color: rgba(0,0,0,0.45);
        padding: 1rem;
    }
}
.project-thumb .project-details {
    position: relative;
    color: $font-color;
    text-align: center;
    line-height: 1.3rem;
    h4 {
        font-size: 1.5rem;
        color: $font-color;
        margin-bottom: 0.5rem;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.25rem;
    }
    h6 {
        display: inline-block;
        color: $dark-grey;
        background-color: $font-color;
        padding: 0 1rem;
        margin-bottom: 1px;
        font-size: 1rem;
        text-transform: uppercase;
        letter-spacing: 0.25rem;
    }
    p {
        color: $font-color;
        text-align: center;
        margin: 2rem 0;
    }
    a.button {
    color: #FFFFFF;
    background-color: rgba(255,255,255,0.15);
    }
    a.button:hover {
    background-color: darken(rgba(0,0,0,0.5), 20%);
    }
}
.project-content {
    padding: 3rem;
}
.post-intro {
    padding: 0 2rem;
    margin-bottom: 2.5rem;
    border-left: 1px solid $light-grey;
    border-right: 1px solid $light-grey;
    p {
        margin-bottom: 0;
    }
}
.filter--all {
    -webkit-transition: all 0.6s ease-in-out; /* Safari */
    transition: all 0.6s ease-in-out;
}
.filters p {
    margin-bottom: 0;
    padding-bottom: 1rem;
    line-height: 2rem;
    border-bottom: 1px solid $light-grey;
    font-size: 1.2rem;
}
.filters {
    a {
        text-transform: uppercase;
        font-weight: 300;
        letter-spacing: 1px;
        outline: 0;
        text-decoration: none;
    }
    span:before {
        content: ' • ';
        padding: 1rem;
    }
    span:first-of-type:before {
        content: '';
    }
    a:hover, a:active, a:focus {
        text-decoration: underline;
    }
    .filter-selected {
        text-decoration: underline;
        font-weight: bold;
    }
}
.projects-number p {
    text-transform: uppercase;
    font-size: 1.2rem;
    letter-spacing: 1px;
}
.projects-number p .filter-number {
    font-weight: bold;
    color:$primary-color;
}
// CV Page
.cv-content {
    font-size: 1.3rem;
    line-height: 2rem;
    a.cv-download {
        line-height: 1.8rem;
        .info {
            text-transform: lowercase;
            font-style: italic;
        }
    }
    .cv-categories {
        p {
            margin-bottom: 3rem;
            padding: 1rem 0rem;
            background-color: $secondary-color;
            line-height: 2rem;
            border-bottom: 1px solid $light-grey;
            font-size: 1.2rem;
        }
        a {
            color: #fff;
            text-transform: uppercase;
            font-weight: 400;
            letter-spacing: 1px;
            outline: 0;
            text-decoration: none;
        }
    }
    .cv-exp, .cv-skills {
        padding-bottom: 0rem;
        text-align: center;
        h2 {
            display: inline-block;
            text-transform: uppercase;
            font-size: 2rem;
            font-weight: 400;
            letter-spacing: 1px;
            padding-bottom: 1rem;
            margin-bottom: 1rem;
        }
        &:last-child {
            padding-bottom:3rem;
        }
    }
    .cv-infos {
        text-transform: uppercase;
        text-align: left;
        padding-left: 2rem;
        margin-bottom: 2rem;
        border-left: 1px dotted $secondary-color;
        @media (#{$bp-larger-than-tablet}) {
            padding-right: 2rem;
            border-right: 1px dotted $secondary-color;
            border-left: none;
            text-align: right;
        }
        .cv-infos--position {}
        .cv-infos--skills {
            letter-spacing: 2px;
            span:before {
                content: '/ ';
            }
            span:after {
                content: ' /';
            }
        }
        .cv-infos--company {
            text-transform: none;
            font-weight: 500;
            a {text-decoration: none;}
            a:hover {text-decoration: underline;}
            img.logo-gl {width:50px;}
            img.logo-ldlc {width:100px;}
            img.logo-atc {width:100px;}
        }
    }
    .cv-skills {
        font-size: 1.5rem;
    }
    .cv-list {
        text-align: left;
        padding: 0 1rem;
        @media (#{$bp-larger-than-tablet}) {
            padding: 0;
        }
        li {
            text-indent: -.5em;
            font-size: 1.5rem;
            line-height: 2rem;
            margin-bottom: 2rem;
        }
        li:before {
            content: "• ";
            color: $secondary-color;
            display: inline-block;
            width: 0.5rem;
        }
    }
}

// Contact Page & Form

.contact {
    padding-top: 3rem;
    .photo-id {
        background-color: $primary-color;
    }
    img {
        width: 10rem;
        margin-bottom: 1.5rem;
    }
    h5 {
        letter-spacing: 0.5rem;
        margin-bottom: 1rem;
    }
}
