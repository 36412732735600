// Variables
//––––––––––––––––––––––––––––––––––––––––––––––––––

// Breakpoints
$bp-larger-than-mobile    : "min-width: 400px" !default;
$bp-larger-than-phablet   : "min-width: 550px" !default;
$bp-larger-than-tablet    : "min-width: 750px" !default;
$bp-larger-than-desktop   : "min-width: 1000px" !default;
$bp-larger-than-desktophd : "min-width: 1200px" !default;

// Colors
$light-grey: rgba(0,0,0,.25) !default;
$dark-grey: rgba(0,0,0,.85) !default;
$primary-color: #234a5f !default;
$secondary-color: #235f50 !default;
$border-color: #bbb !default;
$link-color: rgba(189, 222, 220, 0.8) !default;
$font-color: rgba(255,255,255,.85) !default;

// Typography
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700');
$font-family: "Lato", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif !default;

//Grid Variables
$container-width: 1280px !default;
$container-width-larger-than-mobile: 85% !default;
$container-width-larger-than-phablet: 80% !default;
$total-columns: 12 !default;
$column-width: calc(100 / $total-columns) !default; // calculates individual column width based off of # of columns
$column-margin: 4% !default; // space between columns

// Misc
$global-radius: 0px !default;
