// Utilities
//––––––––––––––––––––––––––––––––––––––––––––––––––

.u-full-width {
    width: 100%;
    box-sizing: border-box;
}
.u-max-full-width {
    max-width: 100%;
    box-sizing: border-box;
}
.u-pull-right {
    float: right;
}
.u-pull-left {
    float: left;
}
.u-center {
    text-align: center;
}
.u-justify {
    text-align: justify;
}
.bold {
    font-weight: bold;
}
.em {
    font-style: italic;
}
.shadow {
    -moz-box-shadow: 0px 0px 5px 0px #656565;
    -webkit-box-shadow: 0px 0px 5px 0px #656565;
    -o-box-shadow: 0px 0px 5px 0px #656565;
    box-shadow: 0px 0px 5px 0px #656565;
    filter:progid:DXImageTransform.Microsoft.Shadow(color=#656565, Direction=NaN, Strength=5);
}
